<template>
  <div class="event_card h-100 d-flex flex-column">
    <div class="event_card__head p-1">
      <div class="card_header_layout">
      </div>
      <img v-if="event.image" :src="event.image.url" class="event_card__head__image">
      <img v-if="event.image == null" src="@/assets/images/svg/email.svg" class="event_card__head__image" />
      <div class="event_card__head__info">
        <h3 class="card-text event_name"> {{ event.name }}</h3>
        <div class="credits">
          <div class="credits_count">
            <span v-if="event.free_event == 1"> {{ $t('Free') }}</span>
            <span v-else> {{ event.credits }}</span>
          </div>
          <div class="credits_word">
            <span v-if="event.free_event == 1"> Event </span>
            <span v-else> Credits </span>
          </div>
        </div>
      </div>
    </div>
    <div class="event_card__body h-100 d-flex flex-column pl-1 pr-1">
      <div class="event_card__body__location">
        <div class="pin_ico">
          <feather-icon icon="MapPinIcon" size="20" />
        </div>
        <div class="location_info">
          <p class="mb-0 location_txt" v-if="event.eventlocation == 2">{{ $t('The Event will be held online') }}</p>
          <p class="mb-0 location_txt" v-if="event.eventlocation == 1"> {{ event.street }} , {{ event.city }}</p>
        </div>
      </div>
      <div class="event_card__body__appointments pl-1 pr-1">
        <div class="sub_card_icon">
          <feather-icon icon="CalendarIcon" size="20" />
        </div>
        <div class="appointments">
          <h3 class="card-text event_card_title mb-0"> {{ $t('Appointments') }} <span class="count">({{
            (event.timeslots ? event.timeslots.length : 0)
          }}) </span></h3>
          <div class="appointments_list" v-if="event.timeslots && event.booking_type == 1">
            <div class="appointments_item_fixed" v-for="(slot , index) in event.timeslots">
              <div class="top_topic">{{ index + 1 }}. {{ slot.topic }}</div>
              <div class="item_time">{{ formatter(slot.start_date + ' ' + slot.start_time) }} -
                {{ formatter(slot.end_date + ' ' + slot.end_time) }}
              </div>
            </div>
          </div>
          <div class="appointments_list" v-if="event.timeslots && event.booking_type == 2">
            <div class="appointments_item_dynanic" v-for="(slot , index) in event.timeslots">
              <div class="top_topic">{{ index + 1 }}. {{ slot.topic }} <span class="item_time">{{ slot.booking_time }}
                  minutes</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-text event_card__body__description h-100" v-html="event.description">

      </div>
    </div>
    <div class="event_card__footer pl-1 pr-1">
      <div class="event_actions">

        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="warning" :to="{ name: 'event-details',
        params: {eventid: event.id}}" class="w-100">
          {{ $t('Book now') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BButtonGroup } from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ListCard',
  props: ['event','tcount'],
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BButtonGroup
  },
  methods: {
    formatter: (value) => {
      return moment(String(value))
          .format('DD.MM.YYYY HH:mm')
    },

  }
}
</script>

<style scoped lang="scss">
$text-color-custom: #FFFFFF;
$bg-color-custom: #F2F2F2;

.event_card {
  background: #E5E5E5;
  border-radius: 5px;
  padding-bottom: 10px;

  &__head {
    border-radius: 5px;
    overflow: hidden;
    height: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    flex-shrink: 0;

    .card_header_layout {
      background: linear-gradient(176.24deg, rgba(255, 255, 255, 0) 7.6%, rgba(64, 64, 64, 0.68) 67.53%, rgba(0, 0, 0, 0.8) 87.47%);
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__info {
      position: relative;
      z-index: 3;
      display: flex;
      align-items: end;
      justify-content: space-between;

      .event_name {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.4px;
        color: $text-color-custom;
      }

      .credits {
        display: flex;
        align-items: center;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 5px;
        padding: 5px 11px;

        .credits_count {
          font-style: normal;
          font-weight: 700;
          font-size: 21px;
          line-height: 26px;
          letter-spacing: 0.4px;
          color: $text-color-custom;
        }

        .credits_word {
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.4px;
          color: $text-color-custom;
        }
      }
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }

  &__body {
    &__location {
      max-height: 61px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding: 20px;
      background: #F2F2F2;
      border-radius: 6px;

      .pin_ico {
        margin-right: 10px;
      }

      .location_txt {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.4px;

      }
    }

    &__appointments {
      background: #F2F2F2;
      border-radius: 6px;
      padding: 20px 5px 15px 20px;
      display: flex;
      margin-top: 10px;

      .appointments {
        margin-left: 10px;

        .event_card_title {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0.4px;
          margin-top: 3px;
          padding-bottom: 15px;

          .count {
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.4px;

          }
        }

        .appointments_list {
          .appointments_item_fixed {
            margin-bottom: 10px;

            .top_topic {
              font-style: normal;
              font-size: 12px;
              line-height: 19px;
              letter-spacing: 0.4px;

            }

            .item_time {
              margin-top: 2px;
              font-style: normal;
              font-size: 12px;
              color: #82868B;
              background: rgba(164, 126, 78, 0.15);
              border-radius: 17px;
              padding: 0 8px;
              font-weight: 500;
              font-size: 12px;
              line-height: 19px;
              text-align: center;
              letter-spacing: 0.4px;
            }
          }

          .appointments_item_dynanic {
            margin-bottom: 10px;

            .top_topic {
              font-style: normal;
              font-size: 12px;
              line-height: 19px;
              letter-spacing: 0.4px;
              font-weight: 800;

              .item_time {

                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                background: rgba(164, 126, 78, 0.15);
                border-radius: 17px;
                padding: 0 8px;
              }
            }

          }
        }
      }
    }

    &__description {
      margin-top: 10px;
      padding: 25px 20px 30px 20px;
      background: #F2F2F2;
      border-radius: 6px;
      //color: #727272;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      letter-spacing: 0.4px;
      align-items: baseline;
      position: relative;

      &:after {
        content: '';
        background: linear-gradient(180deg, rgba(217, 217, 217, 0) 46.46%, #D9D9D9 93.36%);
        position: absolute;
        width: 100%;
        height: 70px;
        left: 0;
        bottom: 0;
      }

    }
  }

  .event_actions {

    .btn {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      z-index: 4;
      position: relative;

      &:first-child {
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
}

.event_card__footer {
  margin-top: -5px;
}

.dark-layout {
  .event_card {
    background: #3d3d40;

    .event_card__body__appointments,
    .event_card__body__location,
    .event_card__body__description {
      background: #444447;

      &:after {
        display: none;
      }
    }

    .event_card__head__info {
      .credits {
        background: #444447;

        .credits_count {
          color: #d0d2d6;
        }

        .credits_word {
          span {
            color: #d0d2d6;
          }
        }
      }

      .event_name {
        color: #d0d2d6;
      }
    }
  }
}
</style>
