<template>
  <b-card
      class="mb-0"
      :title="$t('Events impressions')">

    <swiper
        class="swiper-multiple  px-2"
        :options="swiperOptions"


        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
      <swiper-slide
          v-for="(img,index) in images"
          :key="index">
        <b-img
            :src="img"
            fluid/>
      </swiper-slide>
      <div
          slot="button-next"
          class="swiper-button-next"
      />
      <div
          slot="button-prev"
          class="swiper-button-prev"
      />
    </swiper>
  </b-card>
</template>

<script>

import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import {BImg,BCard} from 'bootstrap-vue'
import 'swiper/css/swiper.css'

export default {
  name: "event-impressions",
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BCard
  },
  data() {
    return {
      swiperOptions: {
        lazy: true,
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      images: [
        'https://www.theladders.com/wp-content/uploads/office-meeting-190912.jpg',
        'https://www.theladders.com/wp-content/uploads/office-meeting-190912.jpg',
        'https://www.theladders.com/wp-content/uploads/office-meeting-190912.jpg',
        'https://www.theladders.com/wp-content/uploads/office-meeting-190912.jpg',
        'https://www.theladders.com/wp-content/uploads/office-meeting-190912.jpg',
      ]

    }
  }
}
</script>

