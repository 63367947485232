<template>
  <div>
    <b-overlay rounded="sm" v-if="loaded">
      <b-card v-if="eventsAvailable" :title="$t('Search for Expert Events')" class="mb-4">
        <div class="">
          <b-row>
            <b-col md="4">
              <validation-provider #default="validationContext" :name="$t('Search')" rules="min:3">
                <b-form-group :label="$t('Name of the Event')">
                  <b-form-input v-model="search" class="d-inline-block mr-1" :placeholder="$t('Search...')"
                    :state="getValidationState(validationContext)" />
                </b-form-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('Event Type')">
                <v-select :placeholder="$t('filter by Event Type')" v-model="filter.eventtypes"
                  :options="filterOptions.eventtypes" name="eventtypes" label="name" multiple
                  :close-on-select="false" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card>
        <h4 v-if="eventsAvailable" class="mb-2"> {{ $t('Expert Events') }}</h4>
        <h4 v-if="!eventsAvailable" class="mb-2"> {{ $t('No active Events available') }}</h4>
        <b-row v-if="eventsAvailable">
          <b-col md="3" v-for="(event, index) in events.data" :key="index" class="mb-2">
            <expertDetailsEventCard :event="event" />
          </b-col>
        </b-row>
        <div class="d-flex justify-content-center">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit" size="sm"
            @click="loadMore" :class="[(events.current_page == events.last_page)?'d-none':'']">
            <b-spinner small v-if="loadMoreData" />
            {{ $t('Load more entries') }}
          </b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardGroup,
  BCarousel,
  BCarouselSlide,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRating,
  BOverlay,
  BRow,
  BTable,
  BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ExpertDetailsCard from "@/views/experts/expert-single/details/ExpertDetailsCard";
import expertDetailsEventCard from "@/views/experts/expert-single/details/ExpertDetailsEventCard";
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min } from '@validations'

export default {
  props: {
    expert: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  components: {
    ExpertDetailsCard,
    BCardGroup,
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRating,
    BTable,
    BCardBody,
    BCardFooter,
    BRow,
    BCarousel,
    BCarouselSlide,
    BCol,
    BOverlay,
    vSelect,
    expertDetailsEventCard,
    ValidationProvider,
    ValidationObserver,
    required,
    BSpinner
  },
  data() {
    return {
      loaded: false,
      slide: 0,
      sliding: null,
      interval: 3000,
      items: [],
      search: '',
      filter: {
        eventtypes: [],
      },
      filterOptions: {
        eventtypes: [],
      },
      events:[],
      page: 1,
      loadMoreData: false,
      eventsAvailable:false,
      searchRequest:false,

    }
  },
  watch: {
    // whenever question changes, this function will run
    search(newSearchQuery, oldSearchQuery) {
      if ((newSearchQuery != oldSearchQuery) && !this.searchRequest && newSearchQuery.length >= 3) {
        this.searchRequest = true
        this.getEvents()
      } else {
        if (!newSearchQuery.length) {
          this.searchRequest = true
          this.getEvents()
        }
      }
    },
    'filter.eventtypes'(newEventTypes, oldEventTypes) {
      if ((newEventTypes != oldEventTypes) && !this.searchRequest) {
        this.searchRequest = true
        this.getEvents()
      }
    },
  },
  mounted() {
    this.interval = 0
    this.loaded = true
    this.getEvents();
    
    
  },
  computed: {
  },
  methods: {
    loadMore() {
      this.loadMoreData = true
      this.getEvents(this.page + 1)
    },
    getEvents(page = 1) {
      this.page = page
      let searchParams = {
        search: this.search,
        eventtypes: this.filter.eventtypes,
      }
      this.$http.get(`/client/experts/events`, 
        { 
            params: { 
              expert_id: this.$route.params.expertid,
              page: this.page,
              search: searchParams
            } 
        }).then((res) => {
          this.loadMoreData = false
          this.searchRequest = false
        if (this.page == 1) {
          this.events = res.data.items
          this.events.current_page = res.data.items.current_page
          this.events.last_page = res.data.items.last_page
        } else {
          this.events.data.push(...res.data.items.data)
          this.events.current_page = res.data.items.current_page
          this.events.last_page = res.data.items.last_page
        }
          this.eventsAvailable = this.events.data.length ? true:false

          this.events.data.forEach((event) => {
            let eventFilterData = {
              id: event.eventtype.id,
              name: event.eventtype.name
            }
            let found = false

            this.filterOptions.eventtypes.forEach((filter) => {
              if (filter.id === eventFilterData.id) found = true
            })
            if (!found) {
              this.filterOptions.eventtypes.push(eventFilterData)
            }
          })
          this.filterOptions.eventtypes.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
  }
}
</script>
<style lang="scss">

.event-description {
  width: 100%;

  p {
    text-align: center;
    width: 100%;
  }
}


.card-profile {
  padding-top: 7rem;
}

.expert-events-card {
  text-align: center;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-wrap: wrap;
  min-height: 700px;
}

.profile-badge {
  + .profile-badge {
    margin-left: 0.5rem;
  }
}

.carousel-img > img {
  max-height: 200px !important;
  min-height: 200px !important;
  object-fit: cover !important;
  object-position: center;
  width: 100%;
  height: 100%;
}

.carousel-interval {
  no-wrap: true
}

.slogan-expert {
  min-height: 110px;
}

.event-locationsection {
  min-height: 70px;
}

.carousel-indicators li {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

.duration {
  text-align: left !important;
  margin-left: 1px;
}

.clock-icon {
  margin-top: 2px;
}

.host-info {
  text-align: center !important;
}

.credits {
  text-align: right !important;
  margin-left: 60px;
}

.minutes {
  margin-left: 5px;
}

.credits-amount {
  margin-left: 5px;
}

.event-location {
  text-align: center !important;
  justify-content: center;
}

.events-duration {
  margin-left: 10px;
}

.events-credits {
  text-align: right !important;
  margin-right: 10px;
}

.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  visibility: hidden;
  opacity: 1;
}

.carousel:hover .carousel-control-prev,
.carousel:hover .carousel-control-next {
  visibility: visible;
  animation-duration: 1s;
  transition-timing-function: linear;
}

.carousel:hover .carousel-control-prev {
  animation-name: FadeInPrev;
}

.carousel:hover .carousel-control-next {
  animation-name: FadeInNext;
}

@keyframes FadeInPrev {
  0% {
    opacity: 0;
    padding-left: 40px;
  }
  100% {
    opacity: 1;
    padding-left: 0px;
  }
}

@keyframes FadeInNext {
  0% {
    opacity: 0;
    padding-right: 40px;
  }
  100% {
    opacity: 1;
    padding-right: 0px;
  }
}

</style>
