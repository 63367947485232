<template>
  <div>
    <b-card
        class="card-profile mb-4 pt-0"
        :img-src="require('@/assets/images/banner/parallax-4.jpg')"
        img-top
        alt="cover photo"
        body-class="p-0">
      <div class="position-relative">
        <div class="profile-img-container ">
          <div class="profile-image p-0  w-100 d-flex flex-column align-items-center ">
            <b-avatar

                style="margin-top: -65px;"
                class="profile-avatar"
                ref="previewEl"
                alt="Avatar"
                :src="currentItem.avatar ? currentItem.avatar.url : ''"
                size="114px"
                variant="light"/>
          </div>
        </div>
        <div class="profile-title">
          <h3 class="mt-1">
            {{ currentItem.firstname }} {{ currentItem.name }}
          </h3>
          <h6 class="text-muted mt-2">
            {{ currentItem.position }} - {{ currentItem.degree }}
          </h6>
          <h6 class="text-muted mt-2">
            {{ currentItem.company }} - {{ currentItem.institute }}
          </h6>
          <h6 class="mt-2">
            {{ currentItem.city }}
          </h6>
          <router-link :to="{ name: 'experts-details', params: {expertid: expertid}}">
            <b-badge
                pill
                variant="light-primary"
                class="mt-1 mb-1 ">
              {{ $t('See available Events') }}
            </b-badge>
          </router-link>
          <hr class="ml-4 mr-4" style="height:1px;border:none;color:#a8a6a6;background-color:#b1b0b0;"/>
          <p>{{ currentItem.slogan }}</p>
          <div class="mb-2 mt-2">
            <span
                v-show="!visible"
                class="cursor-pointer text-muted"
                v-b-toggle.collapse-1>
              {{ $t('Show more') }}
              <feather-icon
                  icon="ChevronDownIcon"
                  class="text-body text-muted"/>
            </span>
          </div>
        </div>
      </div>
      <div class="timeline-container mt-4">
        <div class="mb-2">
          <b-collapse id="collapse-1" class="timeline-experience">
            <app-timeline v-if="currentItem.experiences">
              <div v-for="expiriece in currentItem.experiences" :key="expiriece.id">
                <app-timeline-item>
                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h5>{{ expiriece.job_title }}</h5>
                    <small class="text-muted"> {{ expiriece.time_frame }}</small>
                  </div>
                  <div class="flex-wrap mb-1 mb-sm-0">
                    <b-row>
                      <p class="align-items-left ml-2">{{ expiriece.company_name }}</p>
                    </b-row>
                    <b-row>
                      <p>
                        <span class="text-muted ml-3">{{ expiriece.description }}</span>
                      </p>
                    </b-row>
                  </div>
                </app-timeline-item>
              </div>
            </app-timeline>
          </b-collapse>
        </div>
        <div class="mt-4 mb-4">
          <b-collapse id="collapse-1" class="topics-experience">
            <app-timeline>
              <app-timeline-item variant="primary" v-if="currentItem.experttopics">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5> {{ $t('Experttopics') }}</h5>
                </div>
                <b-row
                    v-for="option in currentItem.experttopics"
                    :key="option.id"
                    :value="option.id">
                  <div class="col-12 mb-1 d-flex justify-content-between align-items-center">
                    <b-badge
                        pill
                        variant="light-primary"
                    >
                      {{ option.name }}
                    </b-badge>
                    <div class="years_side d-flex align-items-center justify-content-end flex-grow-1">
                      <span class="line text-muted custom-line-topics mr-2 flex-grow-1 "></span>
                      <small class="text-muted">{{ option.pivot.experience_years }}</small>
                    </div>
                  </div>
                </b-row>
              </app-timeline-item>
              <app-timeline-item variant="primary">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5>{{ $t('Sections') }}</h5>
                </div>
                <b-row>
                  <b-badge
                      pill
                      v-for="section in currentItem.sections"
                      :key="section.id"
                      v-model="currentItem.sections"
                      :value="section.id"
                      name="sections"
                      variant="light-primary"
                      class=" ml-1 mt-1">
                    {{ section.name }}
                  </b-badge>
                </b-row>
              </app-timeline-item>
              <app-timeline-item variant="primary">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5> {{ $t('Target Groups') }}</h5>
                </div>
                <b-row>
                  <b-form-group>
                    <b-badge
                        pill
                        v-for="targetgroup in currentItem.targetgroups"
                        :key="targetgroup.id"

                        name="targetgroups"
                        variant="light-primary"
                        class="align-items-left ml-1 mt-1"
                    >
                      {{ targetgroup.name }}
                    </b-badge>
                  </b-form-group>
                </b-row>
              </app-timeline-item>
              <app-timeline-item variant="primary">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h5> {{ $t('Languages') }}</h5>
                </div>
                <b-row>
                  <b-badge
                      v-for="language in currentItem.languages"
                      :key="language.key"
                      pill
                      variant="light-primary"
                      class="align-items-left ml-1 mt-1">
                    {{ language.name }}
                  </b-badge>
                </b-row>
              </app-timeline-item>
            </app-timeline>
            <div class="mb-2 mt-2" id="content">
          <span
              class="cursor-pointer text-muted"
              @click="$emit('close-toast')"
              v-b-toggle.collapse-1> {{ $t('Show less') }}
          <feather-icon
              icon="ChevronUpIcon"
              class="text-body text-muted"/>
          </span>
            </div>
          </b-collapse>
        </div>
      </div>
    </b-card>
    <event-impressions/>
    <videonuggets/>
<!--    <reviews/>-->
  </div>
</template>

<script>

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import {
  VBModal,
  VBToggle,
  BBadge,
  BCollapse,
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BLink,
  BMedia,
  BRow,

  BSidebar,
  BToast,

} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ExpertDetailsCard from '@/views/experts/expert-single/details/ExpertDetailsCard'
import ExpertDetailsVideonuggets from '@/views/experts/expert-single/details/ExpertDetailsVideonuggets'
import ExpertDetailsEvents from '@/views/experts/expert-single/details/ExpertDetailsEvents'
import {mapActions, mapGetters} from "vuex";
import Reviews from "@/views/experts/expert-single/profile/Reviews";
import Videonuggets from "@/views/experts/expert-single/profile/Videonuggets";
import EventImpressions from "@/views/experts/expert-single/profile/EventImpressions";


export default {
  components: {
    BBadge,
    BCollapse,
    AppTimeline,
    AppTimelineItem,
    EventImpressions,
    Videonuggets,
    Reviews,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormFile,
    BAvatar,
    BMedia,
    BFormSelect,
    ExpertDetailsCard,
    ExpertDetailsVideonuggets,
    ExpertDetailsEvents,
    VBModal,
    VBToggle,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },

  data() {
    return {
      visible: false,
      loaded: true,
      currentItem : null,
      videoNuggets:[],
      expertid: null,
    }
  },
  methods: {
    ...mapActions(['GET_SINGLE_EXPERT_PROFILE']),
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      this.visible = isJustShown
    })
    this.GET_SINGLE_EXPERT_PROFILE(this.$route.params.expertid).then(responce=>{
      this.expertid = responce.data.id
      this.currentItem = responce.data.expertdetails
      this.videoNuggets = responce.data.videonuggets
    })

  },
}
</script>
<style>

.profile-avatar {
  border-color: pink;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #ffffff;
}

.profile-img-container {
  justify-content: center;
}

.dropdown-setting {
  align-items: end !important;
  margin-left: 930px;
}

.timeline-experience {
  margin-left: 400px;
  margin-right: 400px;
  text-align: left !important;
  /*display: flex !important;*/
}

.topics-experience {
  margin-left: 400px;
  margin-right: 400px;
  /*display: flex !important;*/
}

.experience-years {
  margin-left: 400px;
  position: absolute;
}

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.edit-mode {
  text-align: left !important;
}

.save-button {
  text-align: right !important;
  align-items: flex-end !important;
}

.expert-topics-badge {
  display: grid !important;
}


.custom-line-topics {
  max-width: 470px;
  border-bottom: 1px solid rgb(185, 185, 195);
}
</style>
