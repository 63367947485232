<template>
  <div class="swiper-container mt-4" v-if="swiperData.length">
    <b-card
        class="reviews pb-md-3 pb-sm-0"
        :title="$t('Client Reviews')">
      <swiper
          class="swiper-parallax"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
        <div
            slot="parallax-bg"
            class="parallax-bg"
            data-swiper-parallax="-23%">
        </div>
        <swiper-slide
            v-for="(data,index) in swiperData"
            :key="index">
          <div
              class="title text-center"
              data-swiper-parallax="-300">
            <div class="profile-image mb-2">
              <b-avatar
                  size="84"
                  :src="data.img"/>
            </div>
          </div>
          <div
              class="subtitle text-center mb-1"
              data-swiper-parallax="-200">
            {{ data.subtitle }}
          </div>
          <div
              class="text-center mr-5 ml-5"
              data-swiper-parallax="-100">
            <div class="review-text">
              <b-card-text class="mb-3 custom-color-txt">
                {{ data.text }}
              </b-card-text>
            </div>
            <div class="ratings">
              <b-form-rating
                  class="mt-1"
                  style="background: none"
                  no-border
                  v-model="value2"
                  readonly
                  show-value
                  variant="warning"
                  inline
                  precision="2"/>
            </div>
          </div>
        </swiper-slide>
        <div
            slot="pagination"
            class="swiper-pagination"
        />
        <div
            slot="button-next"
            class="swiper-button-next"
        />
        <div
            slot="button-prev"
            class="swiper-button-prev"
        />
      </swiper>
    </b-card>
  </div>
  <b-card
      v-else
      :title="$t('No client reviews')"
  ></b-card>
</template>

<script>
import {BAvatar, BCard, BFormRating, BImg, BCardText} from 'bootstrap-vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    BImg,
    BCard,
    BAvatar,
    BFormRating,
    BCardText,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      value: 4,
      value2: 3.555,
      swiperData: [
        {
          profile: 'Slide 1',
          img: 'https://w7.pngwing.com/pngs/346/332/png-transparent-woman-s-face-face-skin-care-facial-rhytidectomy-woman-face-people-cosmetics-lip.png',
          subtitle: 'John Doe',
          text: 'Lieferung war wohl auf Grund der aktuellen Situation schwierig und der Umgang seitens Emma damit leider etwas intransparent. Für das Boxspringbett selber kann ...'
        },
        {
          profile: 'Slide 2',
          img: 'https://w7.pngwing.com/pngs/346/332/png-transparent-woman-s-face-face-skin-care-facial-rhytidectomy-woman-face-people-cosmetics-lip.png',
          subtitle: 'Sarah Müller',
          text: 'Ich bin wie immer sehr zufrieden mit der Ware und das schon seit ca.16 jahren! Passt etwas nicht wird es zurückgeschickt und wieder ok.Das kommt aber sehr selte...'
        },
        {
          profile: 'Slide 3',
          img: 'https://w7.pngwing.com/pngs/346/332/png-transparent-woman-s-face-face-skin-care-facial-rhytidectomy-woman-face-people-cosmetics-lip.png',
          subtitle: 'Max Schulze',
          text: 'Diese Fußmatten sind so super! Wir freuen uns jeden Tag daran! Nicht nur schön sondern auch sehr pflegeleicht. Auch nach mehrmaligen waschen sind die Matten wie...'
        },
      ],
      swiperOptions: {
        speed: 600,
        parallax: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 1,

          },
          640: {
            slidesPerView: 1,

          },
        }
      },
    }
  },
}
</script>
<style>
@media screen and (max-width: 560px) {
  .swiper-pagination {
    display: none;
  }
}
.swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: 30px;
}


.swiper-container {
  position: unset;
}

:root {
  --swiper-navigation-size: 24px;
  --swiper-navigation-color: grey;
  --swiper-theme-color: grey;
}

/*.dark-layout .custom-color-txt{*/
/*  color: #727272;*/
/*}*/


</style>
