<template>
  <b-card class="mb-2" :title="$t('Expert Video Nuggets')">
    <b-row>
      <b-col md="4" xl="3" sm="6" v-for="videonugget in videonuggets.data" :key="videonugget.id" class="mb-2">
        <div class="video_card h-100 d-flex flex-column">
          <div class="video_card__head">
            <div class="video_header_layout">
            </div>
            <video class="w-100 h-100" v-if="videonugget.media" :src="videonugget.media.url" ref="videoPlayer" controls
              :poster="videonugget.poster ? videonugget.poster.url : require('@/assets/images/svg/email.svg')"
              preload="auto" loop muted>
              <source type="video/mp4">
            </video>
            <div class="image_header" v-else>
              <img src="@/assets/images/svg/email.svg" class="w-100" />
            </div>
          </div>
          <div class="video_card__body h-100 d-flex flex-column  pl-1 pr-1">
            <div class="video_card__body__title">
              <div class="title_icon">
                <feather-icon icon="YoutubeIcon" size="20" />
              </div>

              <p class="card-text video_title mb-0 ml-2"> {{ videonugget.name }}</p>
            </div>
            <div class="video_card__body__date">
              <p class="card-text date_txt mb-0 pr-2">{{ $t('Release Date') }}:</p> <span class="date_badge"> {{
                formatter(videonugget.startdate) }}</span>
            </div>
            <div class="video_card__body__description h-100">
              {{ videonugget.description }}
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-center">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit" size="sm" @click="loadMore"
        :class="[(videonuggets.current_page == videonuggets.last_page)?'d-none':'']">
        <b-spinner small v-if="loadMoreData" />
        {{ $t('Load more entries') }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardTitle,
  BCol,
  BFormRating,
  BRow,
  BEmbed,
  BSpinner
} from 'bootstrap-vue'
import moment from 'moment'
import {mapActions, mapGetters} from "vuex";
import Ripple from "vue-ripple-directive";
export default {

  components: {
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BFormRating,
    BRow,
    BCol,
    BCardBody,
    BCardFooter,
    BCardTitle,
    BEmbed,
    BSpinner
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loadMoreData: false,
      page: 1,
      videonuggets: [],

    }
  },
  mounted(){
    this.getExperts()
  },
  methods: {
    formatter: (value) => {
      return moment(String(value))
          .format('DD.MM.YYYY')
    },
    loadMore() {
      this.loadMoreData = true
      this.getExperts(this.page + 1)
    },
    getExperts(page = 1) {

      this.page = page
      this.$http.get(`/client/experts/videonuggets`, { params: { expert_id: this.$route.params.expertid, page: this.page } }).then((res) => {
        if (this.page == 1) {
          this.videonuggets = res.data.items
          this.videonuggets.current_page = res.data.items.current_page
          this.videonuggets.last_page = res.data.items.last_page
        } else {
          this.videonuggets.data.push(res.data.items)
          this.videonuggets.current_page = res.data.items.current_page
          this.videonuggets.last_page = res.data.items.last_page
        }
      })
    }
  }
}
</script>
<style lang="scss">

.video_card {
  background: #E5E5E5;
  border-radius: 5px;
  padding-bottom: 10px;

  &__head {
    border-radius: 5px;
    overflow: hidden;
    height: 200px;
    position: relative;
    flex-shrink: 0;

    .card_header_layout {
      background: linear-gradient(176.24deg, rgba(255, 255, 255, 0) 7.6%, rgba(64, 64, 64, 0.68) 67.53%, rgba(0, 0, 0, 0.8) 87.47%);
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

  &__body {
    &__title {
      margin-top: 10px;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      background: #F2F2F2;
      border-radius: 6px;

      .video_title {
        overflow-wrap: break-word;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.4px;

        opacity: 0.6;
      }
    }

    &__date {
      margin-top: 10px;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      background: #F2F2F2;
      border-radius: 6px;

      .date_txt {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.4px;

        opacity: 0.5;
      }

      .date_badge {
        margin-left: 30px;
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.4px;

        background: rgba(164, 126, 78, 0.15);
        border-radius: 17px;
        padding: 0 10px;
      }


    }

    &__description {
      margin-top: 10px;
      padding: 25px 20px 30px 20px;
      background: #F2F2F2;
      border-radius: 6px;

      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      letter-spacing: 0.4px;
      align-items: baseline;
      position: relative;

      overflow-wrap: break-word;


      &:after {
        content: '';
        background: linear-gradient(180deg, rgba(217, 217, 217, 0) 46.46%, #D9D9D9 93.36%);
        position: absolute;
        width: 100%;
        height: 70px;
        left: 0;
        bottom: 0;
      }

    }
  }

  .event_actions {
    .btn {
      padding-top: 1rem;
      padding-bottom: 1rem;

      &:first-child {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}

.dark-layout{
  .video_card{
    background: #3d3d40;

    .video_card__body__title,
    .video_card__body__date,
    .video_card__body__description {

      *{
        opacity: 1;
      }
      background: #444447;
      &:after{
        display: none;
      }
    }

  }
}

</style>
