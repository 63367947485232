<template>
  <b-card>
    <b-row class="expert-card">
      <b-col md="4">
        <div>
          <b-avatar
              class="expert-avatar"
              v-if="expert.expertdetails && expert.expertdetails.avatar"
              size="70"
              :src="expert.expertdetails.avatar.url"
          />
          <div v-if="!expert.expertdetails.avatar" class="empty_user_avatar">
            <feather-icon icon="UserIcon" size="40"/>
          </div>

          <h3 class="mt-2">{{ expert.firstname }} {{ expert.name }}</h3>
          <strong class="text-muted"> {{ expert.expertdetails.slogan }}</strong>
        </div>
        <!--        <div>-->
        <!--          <b-form-rating-->
        <!--              no-border-->
        <!--              variant="warning"-->
        <!--              id="rating-disabled"-->
        <!--              value="4.5"-->
        <!--              readonly-->
        <!--              inline-->
        <!--          />-->
        <!--        </div>-->
      </b-col>


      <b-col md="4">
        <div v-if="expert">
          <h5 class="mb-1">
            {{ $t('Experttopics') }}
          </h5>
          <div v-if="expert.expertdetails.experttopics.length">
            <b-badge
                pill
                class="profile-badge"
                variant="light-primary"
                v-for="experttopic in expert.expertdetails.experttopics"
                :key="experttopic.id"
            >
              {{ experttopic.name }}
            </b-badge>
          </div>
          <div class="topics_list d-flex justify-content-center" v-else>
            <b-badge
                pill
                class="profile-badge"
            >
              {{ $t('The Expert didn’t choose any Topics yet') }}
            </b-badge>
          </div>

        </div>
        <h5 class="mt-1 mb-1">
          {{ $t('Sections') }}
        </h5>
        <div v-if="expert.expertdetails.sections.length">
          <b-badge
              pill
              class="profile-badge"
              variant="light-primary"
              v-for="section in expert.expertdetails.sections"
              :key="'section_' + section.id"
          >
            {{ section.name }}
          </b-badge>
        </div>
        <div class="topics_list d-flex justify-content-center" v-else>
          <b-badge
              pill
              class="profile-badge"
          >
            {{ $t('The Expert didn’t choose any Topics yet') }}
          </b-badge>
        </div>
      </b-col>

      <b-col md="4">
        <div v-if="expert">
          <h5 class="mb-2 text-align-center">
            {{ $t('Informations about the Expert') }}
          </h5>
          <div class="info-text ml-3">
            <b-row>
              <strong class="text-muted text-align-center">{{ expert.expertdetails.position }} -
                {{ expert.expertdetails.degree }}</strong>
            </b-row>
            <b-row class="mt-1">
              <strong class="text-muted text-align-center">{{ expert.expertdetails.company }} -
                {{ expert.expertdetails.institute }}</strong>
            </b-row>
            <b-row class="mt-1">
              <strong class="text-align-center">{{ expert.expertdetails.city }}</strong>
            </b-row>
          </div>
        </div>
      </b-col>


    </b-row>
  </b-card>

</template>

<script>
import { BAvatar, BBadge, BButton, BCard, BCol, BFormRating, BRow } from 'bootstrap-vue'
import moment from 'moment'

export default {
  props: {
    expert: {
      type: Object,
      required: true,
    },
  },
  components: {
    BCard,
    BBadge,
    BAvatar,
    BButton,
    BFormRating,
    BCol,
    BRow
  },

  data() {
    return {
      slide: 0,
      sliding: null,
      interval: 4000,
      item: {},
    }
  },
  filters: {
    moment: function (date) {
      return moment(date)
          .format('DD.MM.YYYY')
    }
  }
}
</script>
<style lang="scss">
.card-profile {
  padding-top: 7rem;
}

.expert-avatar {
  border: 3px solid grey !important;
}

.profile-badge {
  margin: 2px;

  + .profile-badge {
    margin-left: 0.5rem;
  }
}

.expert-card {
  text-align: center;
}

.empty_user_avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid rgba(116, 105, 91, 0.15);
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.topics_list {
  .profile-badge {
    background: rgba(116, 105, 91, 0.15);
    color: #67686a;

    white-space: break-spaces;
    line-height: 13px;
  }
}
.info-text{
  display: inline-block;
}
</style>
